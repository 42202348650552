<template>
  <el-dialog class="pl-4 pr-4 f18" :visible.sync="visible" top="40px" center @close="handleClose" width="960px">
    <div slot="title" class="fwb">部分指标和要求说明</div>
    <div class="pl-5 pr-5 f16">
      <p>（一）指标中如对期限无特殊说明，一般使用企业近 1 年
        的年度数据，具体定义为：指企业上一完整会计年度，以企业
        上一年度审计报告期末数为准。对于存在子公司或母公司的企
        业，按财政部印发的《企业会计准则》有关规定执行。</p>
      <p>
        （二）所称拥有自主品牌是指主营业务产品或服务具有自
        主知识产权，且符合下列条件之一：
        1.产品或服务品牌已经国家知识产权局商标局正式注册。
        2.产品或服务已经实现收入。
      </p>
      <p>
        （三）所称“Ⅰ类知识产权”包括发明专利（含国防专利）、
        植物新品种、国家级农作物品种、国家新药、国家一级中药保
        护品种、集成电路布图设计专有权（均不包含转让未满 1 年的
        知识产权）。
      </p>
      <p>
        （四）所称“Ⅰ类高价值知识产权”须符合以下条件之一：
        1.在海外有同族专利权的发明专利或在海外取得收入的其
        他Ⅰ类知识产权，其中专利限 G20 成员、新加坡以及欧洲专利
        局经实质审查后获得授权的发明专利。
        2.维持年限超过 10 年的Ⅰ类知识产权。
        3.实现较高质押融资金额的Ⅰ类知识产权。
        4.获得国家科学技术奖或中国专利奖的Ⅰ类知识产权。
      </p>
      <p>
        （五）所称“Ⅱ类知识产权”包括与主导产品相关的软件
        著作权（不含商标）、授权后维持超过 2 年的实用新型专利或外
        观设计专利（均不包含转让未满 1 年的知识产权）。
      </p>
      <p>
        （六）所称“企业数字化转型水平”是指在优质中小企业
        梯度培育平台完成数字化水平免费自测，具体自测网址、相关
        标准等事宜，另行明确。
      </p>
      <p>
        （七）所称“重大安全（含网络安全、数据安全）、质量、
        环境污染等事故”是指产品安全、生产安全、工程质量安全、
        环境保护、网络安全等各级监管部门，依据《中华人民共和国
        安全生产法》《中华人民共和国环境保护法》《生产安全事故报
        告和调查处理条例》《中华人民共和国网络安全法》《中华人民
        共和国数据安全法》等法律法规，最高人民法院、最高人民检
        察院司法解释，部门规章以及地方法规等出具的判定意见。
      </p>
      <p>
        （八）所称“股权融资”是指公司股东稀释部分公司股权
        给投资人，以增资扩股（出让股权不超过 30%）的方式引进新
        的股东，从而取得公司融资的方式。
      </p>
      <p>
        （九）所称“合格机构投资者”是指符合《创业投资企业
        管理暂行办法》（发展改革委等 10 部门令第 39 号）或者《私募
        投资基金监督管理暂行办法》（证监会令第 105 号）相关规定，
        按照上述规定完成备案且规范运作的创业投资基金及私募股权投资基金。
      </p>
      <p>
        （十）所称“主导产品”是指企业核心技术在产品中发挥
        重要作用，且产品收入之和占企业同期营业收入比重超过 50%。
      </p>
      <p>
        （十一）所称“主导产品在全国细分市场占有率达 10%以
        上，且享有较高知名度和影响力”可通过企业自证或其他方式
        佐证。
      </p>
      <p>
        （十二）所称“省级科技奖励”包括各省、自治区、直辖
        市科学技术奖的一、二、三等奖；“国家级科技奖励”包括国家
        科学技术进步奖、国家自然科学奖、国家技术发明奖，以及国
        防科技奖。
      </p>
      <p>
        （十三）如无特殊说明，所称“以上”、“以下”，包括本数；
        所称的“超过”，不包括本数。在计算评价指标得分时，如指标
        值位于两个评分区间边界上，按高分计算得分。
      </p>
      <p>
        （十四）本办法部分指标计算公式
        近 2 年主营业务收入平均增长率=（企业上一年度主营业务
        收入增长率+企业上上年度主营业务收入增长率）/2。
        企业上一年度主营业务收入增长率=（企业上一年度主营业
        务收入总额-企业上上年度主营业务收入总额）/企业上上年度主
        营业务收入总额*100%。其他年度主营业务收入增长率计算方
        法以此类推。
      </p>
      <p>
        （十五）所称“被列入经营异常名录”以国家企业信用信
        息公示系统（<a href="http://www.gsxt.gov.cn" target="_blank">http://www.gsxt.gov.cn</a>）查询结果为准；所称“严
        重失信主体名单”以信用中国（<a href="http://www.creditchina.gov.cn" target="_blank">http://www.creditchina.gov.cn</a>）
        查询结果为准。
      </p>
      <p>
        （十六）所称“创客中国”中小企业创新创业大赛全国 500
        强、50 强企业组名单是指该大赛 2021 年以来正式发布的名单。
      </p>
    </div>
    <div slot="footer">
      <el-button type="primary" size="small" @click="handleClose">我已阅读</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'indicator-description',
  data() {
    return {
      visible: false
    }
  },
  methods: {
    init () {
      this.visible = true
    },
    handleClose() {
      this.visible = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
  p {
    text-indent: 2rem;
    line-height: 1.5rem;
  }
</style>
