<template>
  <div class="m-2 eva-bg" v-loading="loading">
    <el-page-header @back="goBack">
      <div slot="content" class="fwb">专精特新企业自评价 <span v-if="orgName" class="c-base">[{{ orgName }}]</span></div>
    </el-page-header>
    <el-divider></el-divider>
    <div v-if="!showResult" class="eva-content">
      <div class="fwb lh2">一、认定条件
        <el-link type="primary" @click="showIndicatorDesc"><span class="el-icon-info"></span> 部分指标和要求说明</el-link>
      </div>
      <div class="lh2">同时满足以下条件即视为满足认定条件：</div>
      <el-form :model="conditionForm" size="small">
        <el-form-item class="condition-row" v-for="(item,index) in conditionList" :key="item.id"
                      :label="`${index + 1}、${item.labelDesc}`"
                      :prop="conditionForm[item.prop]" :rules="{required: true,message: '该项为必填！',trigger: 'blur'}">
          <el-radio-group v-model="conditionForm[item.prop]" v-if="item.prop">
            <el-radio v-for="option in conditionOptions" :label="option.value" :key="option.value">{{ option.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="ml-4">
          <el-form-item class="condition-row" v-for="item in conditionFour" :key="item.id"
                        :prop="condition4Form[item.prop]" :label="item.itemDesc">
            <el-radio-group v-model="condition4Form[item.prop]" v-if="item.prop">
              <el-radio v-for="option in conditionOptions" :label="option.value" :key="option.value">
                {{ option.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <template v-if="showIndicator">
        <div class="lh2 fwb ml-4">二、评价指标</div>
        <el-form :model="indicatorForm" :inline="false" label-position="top" size="small">
          <div class="ml-3" v-for="item in indicatorGroup" :key="item.id">
            <div>{{ item.groupDesc }}</div>
            <div class="ml-4">
              <el-form-item v-for="(indicator,index) in item['indicators']"
                            :label="`${index + 1}、${indicator.indicationDesc}`"
                            :key="indicator.id"
                            :prop="indicator.prop"
                            :rules="{required: true, message: `请说明${indicator.indicationDesc}`, trigger: 'blur'}">
                <template v-if="indicator.prop === $cons.SELF_EVALUATION_QUALITY_LEVEL">
                  <el-checkbox-group v-model="qualityLevel" :max="1">
                    <el-checkbox v-for="option in indicator['optionEntities']" :key="option.optionDesc"
                                 :label="option.optionDesc"></el-checkbox>
                  </el-checkbox-group>
                </template>
                <div class="ml-4" v-else-if="indicator.prop === $cons.SELF_EVALUATION_WORKING_TIME">
                  <el-input-number v-model="numberOfYear" placeholder="从事年限" :min="0"></el-input-number>
                </div>
                <template v-else-if="indicator.prop === $cons.SELF_EVALUATION_SD_INDICATOR">
                  <el-checkbox-group @change="handleCreativeProductChange" v-model="creativeProduct" :max="2">
                    <el-checkbox v-for="option in indicator['optionEntities']" :key="option.optionDesc"
                                 :value="option.optionDesc" :label="option.optionDesc">{{ option.optionDesc }}
                    </el-checkbox>
                  </el-checkbox-group>
                </template>
                <template v-else>
                  <el-radio-group class="ml-3" v-model="indicatorForm[indicator.prop]">
                    <el-radio v-for="option in indicator['optionEntities']" :key="option.optionDesc"
                              :label="option.score">
                      {{ option.optionDesc }}
                    </el-radio>
                  </el-radio-group>
                </template>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </template>
      <div class="tac">
        <el-button @click="handleSubmit" type="primary" size="small" icon="el-icon-edit">提交</el-button>
      </div>
    </div>
    <div v-if="showResult">
      <el-result v-if="finalResult" icon="success" title="评价结果" subTitle="恭喜您，您所评测的企业符合申报专精特新企业的条件！">
        <div class="wi100" slot="extra">
          <el-button type="primary" @click="closeResult" size="small">返回</el-button>
          <el-divider></el-divider>
          <div class="tal">为您推荐以下服务：</div>
          <div class="wi100 uf" style="min-height: 200px" v-loading="recommendLoading">
            <service-card class="animated zoomIn" v-for="service in zjtxServices" :service="service"
                          @showDetail="showServiceDetail(service)"
                          :key="service.id"></service-card>
          </div>
        </div>
      </el-result>
      <el-result v-else icon="error" title="评价结果" subTitle="很抱歉，您所评测的企业不符合申报专精特新企业的条件！">
        <div slot="extra">
          <el-button type="primary" size="small" @click="closeResult">返回</el-button>
        </div>
      </el-result>
    </div>
    <el-dialog :visible.sync="orgVisible" title="公司所在省份" center width="500px">
      <div class="tac">
        <el-select v-model="orgCode">
          <el-option v-for="item in enabledOrgList" :key="item.id" :value="item.code" :label="item.orgName"></el-option>
        </el-select>
      </div>
      <div class="tac mt-3">
        <el-button type="primary" :disabled="!orgCode" @click="selectOrg" size="small" icon="el-icon-edit">确定
        </el-button>
      </div>
    </el-dialog>
    <indicator-description ref="indicator" v-if="indicatorDescVisible"
                           @close="handleIndicatorClose"></indicator-description>
  </div>
</template>

<script>
import ServiceCard from '../../components/service-card'
import IndicatorDescription from '../components/indicator-description'

export default {
  name: 'evaluate',
  components: {
    ServiceCard,
    IndicatorDescription
  },
  data() {
    return {
      loading: false,
      response: {},
      resultForm: {
        workingTime: 0
      },
      // 认定条件一二三结果
      conditionForm: {},
      // 认定条件四结果
      condition4Form: {},
      conditionOptions: [{
        label: '是',
        value: '1'
      }, {
        label: '否',
        value: '0'
      }],
      // 评价指标结果
      indicatorForm: {},
      // 从事特定细分市场年限
      numberOfYear: 0,
      // 分组名称（专、精、特、新）
      indicatorGroup: [],
      // 归属机构
      orgCode: '',
      orgName: '',
      creativeProduct: [],
      qualityLevel: [],
      // 是否显示评价指标
      showIndicator: false,
      // 最终评测结果，是否符合条件
      finalResult: 0,
      showResult: false,
      // 专精特新类服务
      zjtxServices: [],
      recommendLoading: false,
      orgVisible: true,
      enabledOrgList: [],
      // 是否显示指标说明
      indicatorDescVisible: false
    }
  },
  computed: {
    conditionList() {
      let conditions = this.response['conditions'] || []
      conditions.map(function (item) {
        let options = [
          {
            prop: '1',
            label: '是'
          },
          {
            prop: '0',
            label: '否'
          }
        ]
        item.options = options
      })
      return conditions
    },
    conditionFour() {
      return this.response['conditionFour'] || []
    }
  },
  created() {
    this.initEnabledOrg()
  },
  watch: {
    // response(response) {
    //   if (response) {
    //     let conditions = response['conditions'] || []
    //     this.setConditionForm(conditions)
    //     let condition4 = response['conditionFour'] || []
    //     this.setCondition4Form(condition4)
    //   }
    // }
    orgCode(code) {
      if (code) {
        this.orgName = this.enabledOrgList.filter(item => item.code === code)[0].orgName
      }
    },
    numberOfYear(val) {
      let score = 0
      if (val) {
        score = Math.floor(val / 2)
      }
      if (score >= 5) {
        this.indicatorForm['numberOfYear'] = 5
      } else {
        this.indicatorForm['numberOfYear'] = score
      }
    },
    creativeProduct(valueList) {
      let sum = 0
      if (valueList && valueList.size > 0) {
        sum = valueList.size * 2
      }
      this.indicatorForm['creativeProduct'] = sum
    },
    qualityLevel(valueList) {
      let sum = 0
      if (valueList && valueList.size > 0) {
        sum = valueList.size * 3
      }
      this.indicatorForm['qualityLevel'] = sum
    },
    condition4Form: {
      handler: function (data) {
        let condition4Score = 0
        if (data) {
          for (let item in data) {
            condition4Score += data[item] ? parseInt(data[item]) : 0
          }
          if (condition4Score === 0) {
            this.showIndicator = true
          } else {
            this.showIndicator = false
          }
        }
      },
      deep: true
    },
    finalResult(val) {
      if (val) {
        this.listRecommendService()
      }
    }
  },
  methods: {
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    initConditions() {
      this.loading = true
      this.$http({
            url: this.$http.adornUrl(this.$api.EVALUATION.ZJTX.CONDITIONS),
            method: 'GET'
          }
      ).then(({data}) => {
        if (data && data.code === 0) {
          this.response = data.result || {}
        }
        this.loading = false
      })
    },
    initIndicatorGroups() {
      this.$http({
        url: this.$http.adornUrl(this.$api.EVALUATION.ZJTX.INDICATOR_GROUPS),
        method: 'GET',
        params: this.$http.adornParams({
          orgCode: this.orgCode
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.indicatorGroup = data.list || []
        }
      })
    },
    /**
     * 山东省需特殊处理的指标-10.创新生产经营情况（可累计得分，最高不超过4分），每项分值都一样均为2分
     * @param data
     */
    handleCreativeProductChange(data) {
      // this.indicatorForm['creativeProduct'] = data.length * 2
      this.indicatorForm['creativeProduct'] = data.length * 2
    },
    handleSubmit() {
      this.analysis()
    },
    analysis() {
      let conditionScore = 0
      let parentCondition = {...this.conditionForm}
      let badList = ['isInBlackList', 'isForbidden', 'hasAccident']
      let goodList = ['isIndependent', 'isSmall', 'workingTime', 'rdCost', 'totalIncom']
      for (let condition in parentCondition) {
        if (badList.indexOf(condition) >= 0 && parentCondition[condition] === '1') {
          this.finalResult = 0
          this.showResult = true
          return
        } else {
          if (goodList.indexOf(condition) >= 0 && parentCondition[condition] === '0') {
            this.finalResult = 0
            this.showResult = true
            return
          }
        }
      }
      let condition4 = {...this.condition4Form}
      let condition4Score = 0
      for (let condition in condition4) {
        if (condition4[condition] === '1') {
          condition4Score += 1
        }
      }
      // 如果满足条件四中的任一条件，即判定条件四满足
      if (condition4Score > 0) {
        this.finalResult = 1
      } else {
        // 如果条件四条件不满足，则计算指标得分，得分60分以上则判定条件四满足
        let indicatorScore = 0
        let indicatorsForm = {...this.indicatorForm}
        for (let indicator in indicatorsForm) {
          indicatorScore += indicatorsForm[indicator] ? parseInt(indicatorsForm[indicator]) : 0
        }
        if (indicatorScore >= 60) {
          this.finalResult = 1
        }
      }
      this.showResult = true
    },
    // 关闭结果页面
    closeResult() {
      this.showResult = false
    },
    // 查询推荐服务
    listRecommendService() {
      this.recommendLoading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SEARCH),
        method: 'POST',
        params: this.$http.adornParams({
          itemCode: this.$cons.ZJTX_SERVICE_ITEM_CODE,
          page: 1,
          limit: 10
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.zjtxServices = data.page.list || []
        }
        this.recommendLoading = false
      })
    },
    // 选择产品
    showServiceDetail(item) {
      // let itemCodeType = 'search'
      this.$router.push({name: 'service', query: {id: item.id}})
    },
    /**
     * 查询已开通省份
     */
    initEnabledOrg() {
      this.$http({
        url: this.$http.adornUrl(this.$api.EVALUATION.ENABLED_AREA),
        method: 'GET',
        params: this.$http.adornParams({
          evaluationType: this.$cons.EVALUATION_TYPE.ZJTX
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.enabledOrgList = data.org || []
        }
      })
    },
    selectOrg() {
      this.orgVisible = false
      // 初始化认定条件
      this.initConditions()
      // 初始化指标组及具体指标
      this.initIndicatorGroups()
    },
    showIndicatorDesc() {
      this.indicatorDescVisible = true
      this.$nextTick(() => {
        this.$refs.indicator.init()
      })
    },
    handleIndicatorClose() {
      this.indicatorDescVisible = false
    }
  }
}
</script>

<style scoped>
.eva-bg {
  background-color: #FFFFFF;
  min-height: 800px;
  padding: 1rem;
}

.eva-content {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem 0;
}

.condition-row {
  line-height: 2rem;
}
</style>
